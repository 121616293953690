import { SignIn } from '@clerk/remix';
import { MetaFunction } from '@remix-run/cloudflare';
import { ContentLayout } from '~/components/admin-panel/content-layout';
import { RouteHandle } from '~/lib/route-handle';
import { getSeoMeta } from '~/lib/seo';

export const handle: RouteHandle = {
  breadcrumbText: 'Sign In',
};

export const meta: MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Sign In',
    },
    args
  );
};

export default function SignInPage() {
  return (
    <>
      <ContentLayout title="Sign In">
        <div className="flex h-full w-full items-center justify-center">
          <SignIn path="/sign-in"></SignIn>
        </div>
      </ContentLayout>
    </>
  );
}
